<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" color="secondary" matBadgeColor="warn">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
  <span class="parent-span">Open Reader's Bibles<span class="warn">Alpha</span></span>
  <span class="on-the-right" *ngIf="user">
    <button mat-button [matMenuTriggerFor]="menu">
      {{user.user_id}}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logout()">    
        <mat-icon>logout</mat-icon>
        Logout
      </button>
    </mat-menu>
  </span>  
  <span class="on-the-right" *ngIf="!user">
    <button mat-button (click)="login()" id="login">
      <mat-icon>login</mat-icon>
      Sign in
    </button>
  </span>  
  </mat-toolbar-row> 
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav #drawer mode="over" role="navigation" (click)="drawer.toggle()">
    <mat-nav-list>
      <a mat-list-item routerLink='/'><mat-icon matListIcon>home</mat-icon> Home</a>
      <a mat-list-item routerLink='/workspace' *ngIf="user"><mat-icon matListIcon>build</mat-icon> Workspace</a>
      <a mat-list-item routerLink='/myprojects' *ngIf="user"><mat-icon matListIcon>folder_open</mat-icon> Projects</a>
      <a mat-list-item (click)="newProject()" *ngIf="user" class="indented"><mat-icon matListIcon>add</mat-icon> Start a New Project...</a>
      <a mat-list-item routerLink='/join' *ngIf="user" class="indented"><mat-icon matListIcon>group_add</mat-icon> Join an Existing Project</a>
      <a mat-list-item target="_blank" href="https://docs.openreadersbibles.org/"><mat-icon matListIcon>menu_book</mat-icon> Documentation</a>
      <a mat-list-item routerLink='/settings' *ngIf="user"><mat-icon matListIcon>settings</mat-icon> Settings</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
