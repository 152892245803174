import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectConfiguration, ProjectId } from '@models/ProjectConfiguration';
import { UserProfile } from '@models/UserProfile';
import { SynchronizerService } from '../synchronizer.service';
import { MatListModule } from '@angular/material/list';
import { Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-myprojects',
  standalone: true,
  imports: [CommonModule,
    MatListModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule],
  templateUrl: './myprojects.component.html',
  styleUrl: './myprojects.component.scss'
})
export class MyprojectsComponent {

  constructor(
    private router: Router,
    private synchronizerService: SynchronizerService,
    public dialog: MatDialog) {
  }

  get user(): UserProfile | undefined {
    return this.synchronizerService.user$.value;
  }

  get projects(): ProjectConfiguration[] {
    return Array.from(this.user?.projects || []).sort((a: ProjectConfiguration, b: ProjectConfiguration) => b.title.localeCompare(a.title)).reverse();
  }

  changeProject(pid: ProjectId) {
    // let project = this.user?.project(pid);
    // if (project) {
    //   this.synchronizerService.setProject(pid, project.fallbackCanon());
    // }
  }


  // Example method to trigger the download
  downloadFile(content: string, fileName: string, contentType: string) {
    const blob = new Blob([content], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }


  downloadProjectConfiguration(project: ProjectConfiguration) {
    const content = JSON.stringify(project.toObject(), null, 2);
    const fileName = `${project.id}.json`;
    const contentType = 'text/plain';
    this.downloadFile(content, fileName, contentType);
  }

}
