import { Component } from '@angular/core';
import { WorkspaceComponent } from '../workspace/workspace.component';
import { CommonModule } from '@angular/common';
import { UserProfile } from '@models/UserProfile';
import { CognitoService } from '../cognito.service';
import { FeedbackMessageComponent } from '../feedback-message/feedback-message.component';
import { SynchronizerService } from '../synchronizer.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [CommonModule,
    WorkspaceComponent,
    FeedbackMessageComponent],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss'
})
export class WelcomeComponent {
  constructor(
    public synchronizerService: SynchronizerService,
    public cognitoService: CognitoService,
    public dialog: MatDialog
  ) { }

  get user(): UserProfile | undefined {
    return this.synchronizerService.user$.value;
  }

  signUpConfirmation() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        title: 'Before you sign up...',
        message: 'As a reminder, we recommend that you create a username that you don\'t use other places on the internet. For further explanation, see the <a target="_blank" href="https://docs.openreadersbibles.org/security/">security</a> section of the documentation.',
        yes: 'Yes, I understand',
        no: 'Dismiss'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const redirect = encodeURI(environment.cognito.redirect_uri);
        window.location.href = 'https://auth.openreadersbibles.org/signup?response_type=code&client_id=4ph7hthgr71rs0jiq6glf052g0&redirect_uri=' + redirect;
      }
    });
  }

}
