import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, ValidatorFn, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { ProjectConfiguration, ProjectId } from '@models/ProjectConfiguration';
import { combineLatest } from 'rxjs';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { SynchronizerService } from '../synchronizer.service';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule, MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { CanonData, getCanon } from '@models/Canons';
import { Canon, VerseReference } from '@models/VerseReference';
import { HollowPublicationRequest } from '@models/PublicationRequest';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { DatabaseAdapterService } from '../database-adapter.service';
import { BookIdentifier } from '@models/BookIdentifier';
import { PublicationStatusComponent } from '../publication-status/publication-status.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';

function parsingFormatSelected(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value.length > 0 ? null : { empty: true };
  };
}

@Component({
  selector: 'app-publication',
  standalone: true,
  imports: [CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatRadioModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatIconModule,
    PublicationStatusComponent,
    MatExpansionModule,
    MatCheckboxModule
  ],
  templateUrl: './publication.component.html',
  styleUrl: './publication.component.scss'
})
export class PublicationComponent implements OnInit {
  @ViewChild('books') booksList: MatSelectionList | undefined;
  public project: ProjectConfiguration | undefined;
  private _formBuilder = inject(FormBuilder);
  public selectedBooks: BookIdentifier[] = [];
  public commitSha: string | undefined;

  public showPublishButton = true;
  public disablePublishButton = false;

  apiResults: { [key: string]: { status: 'loading' | 'success' | 'failure', message?: string } } = {};

  parsingFormatFormGroup = this._formBuilder.group({});
  chooseBooksFormGroup = this._formBuilder.group({});
  advancedOptionsFormGroup = this._formBuilder.group({
    nopdf: new FormControl(false),
  });

  constructor(
    private synchronizerService: SynchronizerService,
    private route: ActivatedRoute,
    private dbService: DatabaseAdapterService
  ) { }

  ngOnInit() {
    combineLatest([this.route.paramMap, this.synchronizerService.user$]).subscribe(([params, user]) => {
      const id = params.get('projectid') as ProjectId;
      let p = this.synchronizerService.user$.value?.project(id);
      if (p) {
        this.project = p;
        this.populateFormControls();
      }
    });
  }

  populateFormControls() {
    if (this.project) {
      /// Selecting the parsing formats
      this.parsingFormatFormGroup.addControl('latexTemplate', this._formBuilder.control(null));

      this.project.canons.forEach(canon => {
        this.parsingFormatFormGroup.addControl(canon, this._formBuilder.control('', parsingFormatSelected()));
      });

      /// Select the only format, if there is an only format
      this.project.canons.forEach(canon => {
        let n = this.project?.publicationSettings.getNumberOfConfigurations(canon);
        if (n === 1) {
          let formatsIterator = this.project?.publicationSettings.getSettingsForCanon(canon)?.values();
          if (formatsIterator) {
            let firstFormat = formatsIterator.next().value;
            if (firstFormat) {
              let patch: any = {};
              patch[canon] = firstFormat.id;
              this.parsingFormatFormGroup.patchValue(patch);
            }
          }
        }
      });

      /// Selecting the books
      this.project.canons.forEach(canon => {
        getCanon(canon).books.forEach((book) => {
          this.chooseBooksFormGroup.addControl(book, this._formBuilder.control(false));
        });
      });

    }
  }

  demoChapterHeader(number: string) {
    return this.project?.chapterHeader.replace('__CHAPTER__', this.demoChangeNumerals(number));
  }

  demoChangeNumerals(str: string) {
    const numerals = this.project?.numerals;
    if (numerals === undefined || numerals.length != 10) {
      return "error";
    } else {
      for (let i = 0; i < 10; i++) {
        str = str.replace(new RegExp(i.toString(), "g"), numerals[i]);
      }
      return str;
    }
  }

  demoFootnoteMarkers(howmany: number) {
    if (this.project) {
      let markers = this.project.footnoteMarkers;
      let result = "";
      for (let i = 0; i < howmany; i++) {
        result += markers[i % markers.length] + ' ';
      }
      return result;
    }
    return "error";
  }

  canonData(canon: Canon): CanonData {
    return getCanon(canon);
  }

  async publish() {
    let request = this.publicationRequest;

    console.log(request);

    // if (request) {
    //   this.disablePublishButton = true;
    //   let result = await this.dbService.publish(request);
    //   console.log(result);
    //   if (result.operation_status == 'success') {
    //     this.showPublishButton = false;
    //     this.commitSha = result.payload.object.sha;
    //   }
    // }
  }

  get publicationRequest(): HollowPublicationRequest | undefined {
    if (this.project !== undefined) {
      let parsingFormats: any = {};

      this.project.canons.forEach(canon => {
        let choice = this.parsingFormatFormGroup.get(canon)?.value;
        parsingFormats[canon] = choice;
      });

      let nopdf = this.advancedOptionsFormGroup.get('nopdf')?.value || false;

      let templateId = this.parsingFormatFormGroup.get('latexTemplate')?.value || undefined;

      return {
        books: this.selectedBooks.map(b => b.toString()),
        project_id: this.project.id,
        parsing_formats: parsingFormats,
        nopdf: nopdf,
        latex_template_id: templateId
      };
    } else {
      return undefined;
    }
  }

  get atLeastOneSelected(): boolean {
    return this.selectedBooks.length > 0;
  }

  booksSelected(event: MatSelectionListChange) {
    if (this.booksList) {
      this.selectedBooks = this.booksList.selectedOptions.selected.map(o => BookIdentifier.fromString(o.value)).filter(b => b !== undefined) as BookIdentifier[];
    }
  }

  async makeApiCalls(): Promise<void> {
    this.selectedBooks.forEach(b => this.apiResults[b.toString()] = { status: 'loading' });

    let request = this.publicationRequest;
    if (request) {
      let returnValue = await this.dbService.performCheck(request);
      let results = returnValue.payload as { [key: string]: string[] };

      this.apiResults = {};
      for (let bookIdString in results) {
        if (results.hasOwnProperty(bookIdString)) {
          let bookResults = results[bookIdString];
          if (bookResults.length === 0) {
            this.apiResults[bookIdString] = { status: 'success' };
          } else {
            this.apiResults[bookIdString] = {
              status: 'failure',
              message: "Missing glosses: " + bookResults.map(s => {
                let ref = VerseReference.fromString(s);
                return `${ref?.chapter}:${ref?.verse}`;
              }).join(', ')
            };
          }
        }
      }
    }
  }

  get someBookFailsCheck(): boolean {
    return Object.values(this.apiResults).some(r => r.status === 'failure');
  }

  localizedNameFromBookId(bookId: BookIdentifier): string {
    let book = bookId.book;
    // console.log(book);
    // console.log(this.project);
    if (book && this.project) {
      return this.project.getBookName(book);
    }
    return "Unknown";
  }

  selectAllBooks() {
    this.booksList?.selectAll();
    this.chooseBooksFormGroup.updateValueAndValidity();
    if (this.booksList) {
      this.selectedBooks = this.booksList.selectedOptions.selected.map(o => BookIdentifier.fromString(o.value)).filter(b => b !== undefined) as BookIdentifier[];
    }
  }

  selectNoBooks() {
    this.booksList?.deselectAll();
    this.chooseBooksFormGroup.updateValueAndValidity();
    this.selectedBooks = [];
  }
}
