import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Verse } from '@models/Verse';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { PhraseGlossLocation } from '@models/gloss-locations';
import { ProjectTextStyleDirective } from '../project-text-style.directive';
import { ProjectConfiguration } from '@models/ProjectConfiguration';

@Component({
  selector: 'app-verse-text-selector',
  standalone: true,
  imports: [CommonModule, MatButtonModule, ProjectTextStyleDirective],
  templateUrl: './verse-text-selector.component.html',
  styleUrl: './verse-text-selector.component.scss'
})
export class VerseTextSelectorComponent implements OnInit {
  @Input() verse!: Verse;
  @Input() project!: ProjectConfiguration;
  @Output() rangeChanged = new EventEmitter<PhraseGlossLocation | null>();
  selected: Map<number, boolean> = new Map<number, boolean>();

  ngOnInit() {
    this.verse.words.forEach((word) => {
      this.selected.set(word.firstId, false);
    });
  }

  clickWord(value: number) {
    let current = this.selected.get(value) || false;
    this.selected.set(value, !current);
    this.fillInGaps();

    let smallest = this.smallestSelected();
    let largest = this.largestSelected();
    this.rangeChanged.emit(new PhraseGlossLocation(smallest, largest));

    if (this.numberSelected() < 1) {
      this.rangeChanged.emit(null);
    }
  }

  clearSelection() {
    this.selected.forEach((value, key) => {
      this.selected.set(key, false);
    });
    this.rangeChanged.emit(null);
  }

  fillInGaps() {
    if (this.numberSelected() > 1) {
      let smallest = this.smallestSelected();
      let largest = this.largestSelected();
      this.selected.forEach((value, key) => {
        if (key > smallest && key < largest) {
          this.selected.set(key, true);
        }
      });
    }
  }

  numberSelected(): number {
    let count = 0;
    this.selected.forEach((value) => {
      if (value) {
        count++;
      }
    });
    return count
  }

  smallestSelected(): number {
    let smallest = Number.MAX_VALUE;
    this.selected.forEach((value, key) => {
      if (value && key < smallest) {
        smallest = key;
      }
    });
    return smallest;
  }

  largestSelected(): number {
    let largest = 0;
    this.selected.forEach((value, key) => {
      if (value && key > largest) {
        largest = key;
      }
    });
    return largest;
  }

}
