import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoService } from '../cognito.service';
import { TimedOauthCredentials } from '@models/TimedOauthCredentials';

@Component({
  selector: 'app-auth-landing',
  standalone: true,
  imports: [],
  templateUrl: './auth-landing.component.html',
  styleUrl: './auth-landing.component.scss'
})
export class AuthLandingComponent {

  constructor(private route: ActivatedRoute,
    public cognitoService: CognitoService,
    private router: Router) {

    this.route.queryParams.subscribe(params => {
      /// if the 'code' URL parameter has been set, we've been redirected
      /// from the Cognito login page and can now get the auth tokens
      const code = params['code'];
      if (code) {
        /// get the token from Cognito
        this.cognitoService.getToken(code).then(() => {
          /// once we have the token, redirect to the home page
          this.router.navigate(['']);
        });
      } else {
        /// something must be wrong if the code is not set
        /// so redirect to home page to try again
        this.router.navigate(['']);
      }
    });

  }

}
