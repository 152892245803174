import { ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { WordElement } from '@models/WordElement';
import { GlossSelectorLineComponent } from '../gloss-selector-line/gloss-selector-line.component';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Gloss } from '@models/Gloss';
import { Annotation, AnnotationType, NullAnnotation } from '@models/Annotation';
import { SortByVotesPipe } from "../sort-by-votes.pipe";
import { Verse } from '@models/Verse';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { NewGlossDialogComponent } from '../new-gloss-dialog/new-gloss-dialog.component';
import { SynchronizerService } from '../synchronizer.service';

@Component({
  selector: 'app-gloss-selector',
  standalone: true,
  templateUrl: './gloss-selector.component.html',
  styleUrl: './gloss-selector.component.scss',
  imports: [FormsModule, MatFormFieldModule, MatInputModule, FormsModule, CommonModule, GlossSelectorLineComponent, MatInputModule, MatIconModule, SortByVotesPipe, MatMenuModule, MatButtonModule, MatIconModule]
})
export class GlossSelectorComponent {
  @Input() element!: WordElement;
  @Input() verse!: Verse;
  newGloss: string = "";
  readonly dialog = inject(MatDialog);

  constructor(private cd: ChangeDetectorRef, private synchronizerService: SynchronizerService) {
  }

  layoutDirectionLtr(): boolean {
    return this.synchronizerService?.currentProject?.layout_direction === "ltr";
  }

  openDialog(type: AnnotationType): void {
    const dialogRef = this.dialog.open(NewGlossDialogComponent, {
      data: { type: type },
    });

    dialogRef.afterClosed().subscribe(annotation => {
      if (annotation !== undefined) {
        this.addNewGloss(annotation);
      }
    });
  }

  addNewGloss(annotation: Annotation) {
    this.verse.addGlossForLexId(this.element.lex_id, annotation);
    let glossWithVote = Gloss.newGloss(annotation, this.element.location(), true);
    this.element.setVote(glossWithVote);
    this.refresh();
  }

  addNewSimpleGloss() {
    this.openDialog('word');
  }

  addNewMarkdownGloss() {
    this.openDialog('markdown');
  }

  addWordPlusMarkdownGloss() {
    this.openDialog('wordplusmarkdown');
  }

  addEmptyGloss() {
    this.addNewGloss(new NullAnnotation());
  }

  refresh() {
    this.cd.detectChanges();
  }

}
