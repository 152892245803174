import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-font-selector',
  standalone: true,
  imports: [CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule],
  templateUrl: './font-selector.component.html',
  styleUrls: ['./font-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FontSelectorComponent),
      multi: true
    }
  ]
})
export class FontSelectorComponent implements OnInit, ControlValueAccessor {
  fontControl = new FormControl();
  filteredFonts!: Observable<string[]>;
  @Input() label!: string;
  @Input() initial: string = "";
  @Output() fontSelected = new EventEmitter<string>();

  private onChange: (value: string) => void = () => { };
  private onTouched: () => void = () => { };

  private fonts: string[] = [];
  private destroy$ = new Subject<void>();

  // private onChange: (value: string) => void;
  // private onTouched: () => void;

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.fontControl.setValue(this.initial || '');

    // Fetch the fonts data once during initialization
    this.http.get<string[]>('assets/font-families.json').subscribe(fonts => {
      this.fonts = fonts;
      this.filteredFonts = this.fontControl.valueChanges.pipe(
        startWith(''),
        debounceTime(300), // Wait for the user to stop typing for 300ms
        map(value => this.filterFonts(value))
      );
      if (this.fonts.indexOf(this.initial) >= 0) {
        this.fontControl.setValue(this.initial);
      }
    });

    // Emit the selected font when the value changes
    this.fontControl.valueChanges.subscribe(value => {
      if (this.onChange) {
        this.onChange(value);
      }
    });
  }

  writeValue(value: string): void {
    this.fontControl.setValue(value, { emitEvent: false });
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.fontControl.disable();
    } else {
      this.fontControl.enable();
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private filterFonts(query: string): string[] {
    const filterValue = query.toLowerCase();
    return this.fonts.filter(font => font.toLowerCase().includes(filterValue));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onBlur(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

}