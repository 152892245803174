import { Component, OnInit } from '@angular/core';
import { DatabaseAdapterService } from '../database-adapter.service';
import { ActivatedRoute } from '@angular/router';
import { ProjectConfiguration, ProjectId } from '@models/ProjectConfiguration';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { SynchronizerService } from '../synchronizer.service';
import { Canon, UbsBook } from '@models/VerseReference';
import { combineLatest } from 'rxjs';
import { canonicalOrderSort } from '@models/Canons';
import { MatIconModule } from '@angular/material/icon';


class FileInfo {
  path: string;
  date: Date;
  canon: Canon;
  ubs: UbsBook;

  // Convert the Date object to a string with date and time in the user's local time zone
  static options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  };
  static book_regex = /(?<=_)([^_]+)(?=\.pdf)/;
  static canon_regex = /(?<=_)([^_]+)(?=_)/;

  constructor(path: string, datestring: string) {
    this.path = path;
    this.date = new Date(datestring);

    const ubsMatch = this.path.match(FileInfo.book_regex);
    this.ubs = ubsMatch?.at(0) as UbsBook;
    const canonMatch = this.path.match(FileInfo.canon_regex);
    this.canon = canonMatch?.at(0) as Canon;
  }

  get localeDate() {
    return this.date.toLocaleString(undefined, FileInfo.options);
  }

};

@Component({
  selector: 'app-pdfs',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatListModule,
    MatIconModule
  ],
  templateUrl: './pdfs.component.html',
  styleUrl: './pdfs.component.scss'
})
export class PDFsComponent implements OnInit {
  public files: FileInfo[] | undefined;
  private projectid: string | undefined;
  public structuredFiles = new Map<Canon, FileInfo[]>();
  public project: ProjectConfiguration | undefined;

  constructor(
    private route: ActivatedRoute,
    private synchronizerService: SynchronizerService,
    private dbService: DatabaseAdapterService
  ) {
  }

  async ngOnInit() {
    combineLatest([this.route.paramMap, this.synchronizerService.user$]).subscribe(([params, user]) => {
      this.projectid = params.get('projectid') as ProjectId;
      let p = this.synchronizerService.user$.value?.project(this.projectid);
      if (p) {
        this.project = p;
        this.downloadFiles(this.project);
      }
    });
  }

  async downloadFiles(project: ProjectConfiguration) {
    const result = await this.dbService.getPublicationFiles(project.id);
    if (!Array.isArray(result)) {
      this.files = [];
    } else {
      this.files = result.map((f) => new FileInfo(f.path, f.lastModifiedDate));
      // console.log(this.files)
      this.structuredFiles.clear();

      /// Group the files by canon
      this.files.forEach(file => {
        if (this.structuredFiles.has(file.canon)) {
          this.structuredFiles.get(file.canon)?.push(file);
        } else {
          this.structuredFiles.set(file.canon, [file]);
        }
      });
      /// sort the books
      this.project?.canons.forEach(canon => {
        if (this.structuredFiles.has(canon)) {
          this.structuredFiles.get(canon)?.sort((a, b) => canonicalOrderSort(a.ubs, b.ubs));
        }
      });
      // console.log(this.structuredFiles);
    }
  }

  linkToPDF(file: FileInfo) {
    if (this.projectid) {
      return `https://github.com/openreadersbibles/${ProjectConfiguration.getRepositoryName(this.projectid)}/raw/refs/heads/gh-pages/${file.path}`;
    } else {
      return '';
    }
  }

  localName(file: FileInfo) {
    if (this.projectid) {
      const regex = /(?<=_)([^_]+)(?=\.pdf)/;
      const ubs = file.path.match(regex);
      if (ubs) {
        let name = this.project?.getBookName(ubs[0] as UbsBook);
        return name;
      }
    }
    return file.path;
  }
}
