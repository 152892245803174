<ng-container *ngIf="element">
  <div [class.ltr]="layoutDirectionLtr()" [class.rtl]="!layoutDirectionLtr()">
    <ng-container *ngFor="let gloss of element.glossSuggestions | sortByVotes">
      <app-gloss-selector-line [gloss]="gloss" [container]="element"></app-gloss-selector-line>
    </ng-container>

    <div class="button-container" [class.ltr]="layoutDirectionLtr()" [class.rtl]="!layoutDirectionLtr()">
      <mat-icon (click)="addNewSimpleGloss()">add</mat-icon>
      <mat-icon [matMenuTriggerFor]="menu">more_horiz</mat-icon>
    </div>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="addNewSimpleGloss()">Add simple gloss...</button>
      <button mat-menu-item (click)="addWordPlusMarkdownGloss()">Add simple gloss & note...</button>
      <button mat-menu-item (click)="addNewMarkdownGloss()">Add freeform gloss...</button>
      <button mat-menu-item *ngIf="!(element.hasEmptyGloss)" (click)="addEmptyGloss()">Add empty gloss</button>
    </mat-menu>

  </div>
</ng-container>