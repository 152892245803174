<p>This is where you create <em>new</em> PDF versions of your books. If you're looking for the PDFs that you have already created, <a [routerLink]="['/pdfs', project?.id]">click here</a>.</p>

<mat-stepper orientation="vertical" [linear]="true" #stepper *ngIf="project">
  <!--  (selectionChange)="onStepChange($event)" -->
    <mat-step>
      <ng-template matStepLabel>Confirm the publication settings</ng-template>

      <p>If any of these don't look right, you can change them in the <a [routerLink]="['/settings/project', project.id]">Project Settings</a>.</p>

      <table class="settings">
        <tr>
          <th>Project Language Font</th>
          <td>{{project.publicationProjectFont}}</td>
        </tr>
        <tr>
          <th>Biblical Language Font</th>
          <td>{{project.publicationBiblicalFont}}</td>
        </tr>
        <tr>
          <th>Typography Language</th>
          <td>{{project.polyglossiaOtherLanguage}}</td>
        </tr>
        <tr>
          <th>Numbers</th>
          <td>{{demoChangeNumerals("1234567890")}}</td>
        </tr>
        <tr>
          <th>Sample Chapter Header</th>
          <td>{{demoChapterHeader("15")}}</td>
        </tr>
        <tr>
          <th>First ten footnotes</th>
          <td>{{demoFootnoteMarkers(10)}}</td>
        </tr>
      </table>

      <div>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="parsingFormatFormGroup">
      <form [formGroup]="parsingFormatFormGroup">
        <ng-template matStepLabel>Publication format</ng-template>

        <p>For each canon, select a parsing format. If there are no parsing formats, you can add them in the <a [routerLink]="['/settings/project', project.id]">Project Settings</a>.</p>

        @for (c of project.canons; track c) {
            <h3>Choose a parsing format for the {{c}} canon:</h3>
            @if( project.publicationSettings.getNumberOfConfigurations(c) > 0 ) {
              <mat-radio-group aria-label="Select a format" [formControlName]="c">
              @for(key of project.publicationSettings.getSettingsForCanon(c)?.keys(); track key ) {
                <mat-radio-button [value]="project.publicationSettings.getSettingsForCanon(c)?.get(key)?.id">{{project.publicationSettings.getSettingsForCanon(c)?.get(key)?.id}}</mat-radio-button>
              }
              </mat-radio-group>
          } @else {
              <p>There are no formats for this canon yet.</p>
            }
        }

        <ng-container *ngIf="project.latex_templates.size > 0">
          <h3>Choose publication format:</h3>
          <mat-radio-group aria-label="Select a template" formControlName="latexTemplate" [value]="null">
            <mat-radio-button [value]="null">Default Format</mat-radio-button>
            @for(key of project.latex_templates.keys(); track key) {
              <mat-radio-button [value]="key">{{key}}</mat-radio-button>
            }
          </mat-radio-group>

        </ng-container>

        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext [disabled]="!parsingFormatFormGroup.valid">Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="chooseBooksFormGroup">
      <form [formGroup]="chooseBooksFormGroup">
      <ng-template matStepLabel>Choose books</ng-template>
      <p>Select the books you want to include in the publication.</p>
      <mat-selection-list (selectionChange)="booksSelected($event)" #books>
        @for (c of project.canons; track c) {
          <h3 class="mat-title">{{c}}</h3>
          @for (book of canonData(c).books; track book; let i = $index) {
            <mat-list-option [value]="c + ' ' + book">{{project.getBookName(book)}}</mat-list-option>
          }
      }
      </mat-selection-list>

      <button mat-button type="button" (click)="selectAllBooks()">Select All</button>
      <button mat-button type="button" (click)="selectNoBooks()">Select None</button>
      
      <div *ngIf="!atLeastOneSelected">
        <p style="color: red;">At least one book must be selected.</p>
      </div>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext type="button" [disabled]="!atLeastOneSelected" (click)="makeApiCalls()">Next</button>
      </div>
      </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Check books</ng-template>
      <p>If any of the books fail the check, you need to add in the missing glosses before publishing the book. (You can go back and unselect the book if you don't want to deal with that right now.)</p>
      <div *ngFor="let book of selectedBooks">
        <div *ngIf="apiResults[book.toString()]">
          <ng-container [ngSwitch]="apiResults[book.toString()].status">
            <div *ngSwitchCase="'loading'">
              <mat-progress-spinner diameter="20"></mat-progress-spinner> Checking {{ book }}...
            </div>
            <div *ngSwitchCase="'success'" class="results-div">
              <mat-icon style="color: green;">check_circle</mat-icon> <span style="color: green;">Success: {{ localizedNameFromBookId(book) }}</span> 
            </div>
            <div *ngSwitchCase="'failure'" class="results-div">
              <mat-icon style="color: red;">error</mat-icon> <span style="color: red;">Failure: {{ localizedNameFromBookId(book) }}: {{ apiResults[book.toString()].message }}</span> 
            </div>
          </ng-container>
        </div>
      </div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext [disabled]="someBookFailsCheck">Next</button>
  </mat-step>

    <mat-step>

      <ng-template matStepLabel>Publish</ng-template>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Advanced options</mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="advancedOptionsFormGroup">
          <mat-checkbox formControlName="nopdf">Do not generate PDFs, only JSON and TeX</mat-checkbox>
        </form>
      </mat-expansion-panel>

      <div *ngIf="showPublishButton">
        <p>You're all set! Click the button below to publish your books!</p>
        <button mat-button (click)="publish()" [disabled]="disablePublishButton">
          <mat-icon *ngIf="disablePublishButton" class="spinner">autorenew</mat-icon>
          {{ disablePublishButton ? 'Publishing...' : 'Publish' }}
          </button>  
      </div>

      <app-publication-status [commitSha]="commitSha" [project]="project.id"></app-publication-status>

    </mat-step>
  </mat-stepper>