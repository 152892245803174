import { Component } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UserProfile } from '@models/UserProfile';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CognitoService } from './cognito.service';
import { SynchronizerService } from './synchronizer.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDialog } from '@angular/material/dialog';
import { ProjectIdInputDialogComponent } from './project-id-input-dialog/project-id-input-dialog.component';
import { filter, first } from 'rxjs';
import { TimedOauthCredentials } from '@models/TimedOauthCredentials';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet,
    RouterModule,
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule],
  providers: [/*provideHttpClient(withInterceptorsFromDi())*/]
})
export class AppComponent {
  public isDevEnvironment: boolean = false;

  constructor(public cognitoService: CognitoService,
    private synchronizerService: SynchronizerService,
    private router: Router,
    public dialog: MatDialog) {
    this.isDevEnvironment = window.location.href.indexOf('dev.openreadersbibles.org') != -1;

    this.cognitoService.accessToken$
      .pipe(
        filter(token => token !== null && token !== undefined),
        first()
      )
      .subscribe((token) => {
        // console.log("AppComponent.accessToken$", token);
        this.cognitoService.storeAccessToken(token as TimedOauthCredentials);
        if (token) {
          this.synchronizerService.initializeApplication()
            .then(() => {
              if (this.synchronizerService.user$.value === undefined) {
                console.log("User is undefined even after calling SynchronizerService.initializeApplication()");
              }
            });
        }
      });

    // console.log("AppComponent.constructor", this.cognitoService.accessToken$.value);
    /// check if it's been stored
    this.cognitoService.ensureStoredAccessTokenIsFresh();
  }

  get user(): UserProfile | undefined {
    return this.synchronizerService.user$.value;
  }

  navigateToSettings() {
    this.router.navigate(['/settings']);
  }

  navigateToWorkspace() {
    this.router.navigate(['/workspace']);
  }

  login() {
    window.location.href = this.cognitoService.loginUrl;
  }

  logout() {
    this.synchronizerService.user$.next(undefined);
    localStorage.clear();
    this.router.navigate(['/']);
  }

  newProject(): void {
    const dialogRef = this.dialog.open(ProjectIdInputDialogComponent, {
      width: '400px',
      data: { text: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/settings/project/' + result]);
      }
    });
  }

}
