<main>
<h2>{{project?.title}} PDFs</h2>
<mat-progress-bar mode="indeterminate" *ngIf="files === undefined"></mat-progress-bar>

<ng-container *ngIf="files && project && structuredFiles">
<mat-list *ngFor="let canon of project.canons">
    <h3 class="mat-title" *ngIf="(structuredFiles.get(canon)?.length || 0) > 0">{{canon}}</h3>
    <a *ngFor="let file of structuredFiles.get(canon)" [href]="linkToPDF(file)">
        <mat-list-item>
            <mat-icon matListItemIcon>download</mat-icon>
            <span matListItemTitle>{{localName(file)}}</span>
            <span matListItemLine>{{file.path}}, Last Modified: {{file.localeDate}}</span>
        </mat-list-item>
    </a>
</mat-list>
</ng-container>

<p *ngIf="files && files.length === 0">There are no PDFs to show.</p>
</main>