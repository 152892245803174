<mat-progress-bar mode="indeterminate" *ngIf="status === undefined && commitSha != undefined"></mat-progress-bar>
<div *ngIf="status || conclusion" class="status-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Publication Status</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="status" class="status-item">
        <mat-icon [ngClass]="getStatusClass(status)" class="status-icon">hourglass_empty</mat-icon>
        <strong>Status: </strong><span> {{ status | titlecase }}</span>
      </div>
      <div *ngIf="conclusion && status === 'completed'" class="status-item">
        <mat-icon [ngClass]="getConclusionClass(conclusion)" class="status-icon">check_circle</mat-icon>
        <strong>Conclusion: </strong><span> {{ conclusion | titlecase }}</span>
      </div>
      <div *ngIf="conclusion === 'success' && project" class="status-item">
        <p>Your files are ready in the project's <a [routerLink]="['/pdfs', project]">PDF files</a>.</p>
        </div>
    </mat-card-content>
  </mat-card>
</div>