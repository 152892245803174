import { Component, Input } from '@angular/core';
import { DatabaseAdapterService } from '../database-adapter.service';
import { WorkflowRun } from './WorkflowRun';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ProjectConfiguration, ProjectId } from '@models/ProjectConfiguration';
import { RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-publication-status',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    RouterModule,
    MatProgressBarModule
  ],
  templateUrl: './publication-status.component.html',
  styleUrl: './publication-status.component.scss'
})
export class PublicationStatusComponent {
  @Input() public commitSha: string | undefined;
  @Input() public project!: ProjectId | undefined;
  status: 'queued' | 'in_progress' | 'completed' | undefined;
  conclusion: 'action_required' | 'cancelled' | 'failure' | 'neutral' | 'success' | 'skipped' | 'stale' | 'timed_out' | undefined;
  private intervalId: any;

  constructor(private dbService: DatabaseAdapterService) { }

  async ngOnChanges() {
    if (this.commitSha && this.repository) {
      this.startPolling();
    }
  }

  startPolling() {
    this.intervalId = setInterval(async () => {
      if (this.repository && this.commitSha) {
        let result = await this.dbService.getActionsForCommit(this.repository, this.commitSha);
        if (result.total_count === undefined || result.total_count < 0 || result.workflow_runs === undefined || result.workflow_runs.length === 0) {
          console.error("Unexpected server response:", result);
          console.error("Parameters", this.repository, this.commitSha);
        } else {
          let run = result.workflow_runs[0] as WorkflowRun;
          console.log(run);

          this.status = run.status;
          this.conclusion = run.conclusion;

          if (this.status === 'completed') {
            clearInterval(this.intervalId);
          }
        }
      }
    }, 3000); // Poll every 3 seconds
  }


  get repository(): string | undefined {
    if (this.project) {
      return ProjectConfiguration.getRepositoryName(this.project);
    }
    return undefined;
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'queued':
        return 'status-queued';
      case 'in_progress':
        return 'status-in-progress';
      case 'completed':
        return 'status-completed';
      default:
        return '';
    }
  }

  getConclusionClass(conclusion: string): string {
    switch (conclusion) {
      case 'action_required':
        return 'conclusion-action-required';
      case 'cancelled':
        return 'conclusion-cancelled';
      case 'failure':
        return 'conclusion-failure';
      case 'neutral':
        return 'conclusion-neutral';
      case 'success':
        return 'conclusion-success';
      case 'skipped':
        return 'conclusion-skipped';
      case 'stale':
        return 'conclusion-stale';
      case 'timed_out':
        return 'conclusion-timed-out';
      default:
        return '';
    }
  }

}
