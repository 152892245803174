<ng-container *ngIf="gloss">
    <!--  [class.highlight]="gloss.changed" -->
    <div 
        class="line" 
        (click)="toggleVote()"
        [attr.votes]="gloss.votes" 
        [class.currentVote]="gloss.isMyVote" 
        [class.ltr]="layoutDirectionLtr()" 
        [class.rtl]="!layoutDirectionLtr()">
        <span class="gloss" *ngIf="gloss.annotationType != 'null'" [innerHTML]="gloss.html" [appProjectTextStyle]="synchronizerService.currentProject"></span>
        <span class="gloss null" *ngIf="gloss.annotationType === 'null'">empty</span>
        <mat-icon>check</mat-icon>
        <span class="votes">{{gloss.votes}}</span>
    </div>
</ng-container>