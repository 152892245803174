import { Component, inject } from '@angular/core';
import { Verse } from '@models/Verse';
import { CommonModule } from '@angular/common';
import { WordDisplayComponent } from '../glossable-word-display/glossable-word-display.component';
import { MatCardModule } from '@angular/material/card';
import { PhraseGlossDisplayComponent } from '../phrase-gloss-display/phrase-gloss-display.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PhraseGlossEditorDialogComponent } from '../phrase-gloss-editor-dialog/phrase-gloss-editor-dialog.component';
import { Gloss } from '@models/Gloss';
import { PhraseGlossLocation } from '@models/gloss-locations';
import { SynchronizerService } from '../synchronizer.service';

@Component({
  selector: 'app-verse-display',
  standalone: true,
  templateUrl: './verse-display.component.html',
  styleUrl: './verse-display.component.scss',
  imports: [CommonModule, WordDisplayComponent, MatCardModule, PhraseGlossDisplayComponent, MatIconModule, MatButtonModule, MatProgressSpinnerModule]
})
export class VerseDisplayComponent {
  readonly dialog = inject(MatDialog);

  constructor(private synchronizerService: SynchronizerService) {
  }

  get verse(): Verse | undefined | null {
    return this.synchronizerService.verse$.value;
  }

  get phraseGlosses(): Map<string, Gloss[]> {
    return Verse.sortPhraseGlosses(this.verse?.phraseGlosses || []);
  }

  get phraseGlossKeys(): PhraseGlossLocation[] {
    return Array.from(this.phraseGlosses.keys()).map(key => PhraseGlossLocation.fromString(key)).sort(PhraseGlossLocation.compare);
  }

  addPhrasalGloss(): void {
    const dialogRef = this.dialog.open(PhraseGlossEditorDialogComponent, {
      data: { verse: this.verse, project: this.synchronizerService.currentProject },
    });

    dialogRef.afterClosed().subscribe((g: Gloss) => {
      if (g && this.verse) {
        g.markAsChanged();
        this.verse.addPhraseGloss(g);
        console.log(this.verse);
      }
    });
  }

}
