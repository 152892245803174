import { Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { ProjectDescription, ProjectId } from '@models/ProjectConfiguration';
import { DatabaseAdapterService } from '../database-adapter.service';
import { Router, RouterModule } from '@angular/router';
import { SynchronizerService } from '../synchronizer.service';

@Component({
  selector: 'app-join-project',
  standalone: true,
  imports: [MatListModule, RouterModule],
  templateUrl: './join-project.component.html',
  styleUrl: './join-project.component.scss'
})
export class JoinProjectComponent {
  availableProjects: ProjectDescription[] = [];

  constructor(private synchronizerService: SynchronizerService,
    private dbService: DatabaseAdapterService,
    private router: Router) {
    this.dbService.getProjectDescriptions()
      .then((projects) => {
        this.availableProjects = projects.filter((project: ProjectDescription) => project.allow_joins);
      });
  }

  join(project_id: ProjectId) {
    this.dbService.joinProject(project_id)
      .then(() => {
        this.synchronizerService.reloadUserData()
          .then(() => {
            const project = this.synchronizerService.user$.value?.project(project_id);
            if (project) {
              this.synchronizerService.setProject(project.id, project.fallbackCanon());
              this.router.navigate(['/workspace', project_id]);
            }
          });

        console.log("Joined project", project_id);
      });
  }

}
