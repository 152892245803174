import { Injectable } from '@angular/core';
import { CanonData } from '@models/Canons';
import { CurrentPosition } from '@models/CurrentPosition';
import { ProjectConfiguration, ProjectId } from '@models/ProjectConfiguration';
import { Verse } from '@models/Verse';
import { Canon, VerseReference } from '@models/VerseReference';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatabaseAdapterService } from './database-adapter.service';
import { UserId, UserProfile } from '@models/UserProfile';
import { CognitoService } from './cognito.service';
import { CognitoUserInfoResponse } from '@models/TimedOauthCredentials';

@Injectable({
  providedIn: 'root'
})
export class SynchronizerService {
  private _user$: BehaviorSubject<UserProfile | undefined> = new BehaviorSubject<UserProfile | undefined>(undefined);
  private _position$: BehaviorSubject<CurrentPosition | undefined> = new BehaviorSubject<CurrentPosition | undefined>(undefined);
  private _verse$: BehaviorSubject<Verse | undefined | null> = new BehaviorSubject<Verse | undefined | null>(undefined);

  constructor(private dbService: DatabaseAdapterService, public cognitoService: CognitoService) { }

  async initializeApplication(): Promise<void> {
    return this.cognitoService.initializeApplication()
      .then((userInfo: CognitoUserInfoResponse) => {
        /// We've now got the user authenticated from the cognito service
        // console.log("Back from Cognito.initializeApplication", userInfo);

        return this.dbService.getUserData(userInfo.username)
          .then((user) => {
            // console.log(user);
            if (user === undefined) {
              this.user$.next(undefined);
              console.error("User data is undefined. We should insert the user into the database.");
            } else {
              /// add it to the local data
              /// 2024-08-22: No need to do this, I'll just pass the username as a parameter
              this.user$.next(user);
              /// counterintutively, we need to get the user
              /// data from the server again, so that it will
              /// include all of the appropriate project data
              this.setCurrentUser(userInfo.username)
                .then((user) => {
                  // console.log("User data", user);
                  // console.log(this._user$.value);

                  /// get the current position
                  this._readCurrentPositionFromLocalStorage();

                  // console.log("Current position", this._position$.value);
                });
            }
          })
          .catch((error) => {
            console.error("Error getting user data", error);
            this.user$.next(undefined);
          });

      })
      .catch((error: any) => {
        console.error("Error with cognito initializeApplication", error);
        this._user$.next(undefined);
      });

  }

  get verse$(): BehaviorSubject<Verse | undefined | null> {
    return this._verse$;
  }

  get user$(): BehaviorSubject<UserProfile | undefined> {
    return this._user$;
  }

  /// Observables are read-only, so this prevents the value from being changed from outside the service
  get position$(): Observable<CurrentPosition | undefined> {
    return this._position$.asObservable();
  }

  /* Convenience functions */
  get currentProject(): ProjectConfiguration | undefined {
    return this._user$.value?.projectFromPosition(this._position$.value);
  }

  get currentPosition(): CurrentPosition | undefined {
    return this._position$.value;
  }

  get currentCanonData(): CanonData | undefined {
    return this.currentPosition?.canonData;
  }

  get currentUser(): UserProfile | undefined {
    return this._user$.value;
  }

  setCanon(c: Canon) {
    // console.log("Setting canon", c);
    if (this.currentPosition) {
      /// confirmed that comparing objects of type Canon checks the string level
      if (this.currentPosition.canon !== c) {
        this.currentPosition.canon = c;
        // this._position$.next(this.currentPosition);
        this._handleVerseTransition(this.currentPosition.ref);
      }
    }
  }

  setProject(id: ProjectId, c: Canon) {
    // console.log("Setting project", id, c);
    if (this.currentPosition) {
      /// confirmed that comparing objects of type ProjectId and Canon checks the string level
      if (this.currentPosition.project_id !== id || this.currentPosition.canon !== c) {
        this.currentPosition.changeProject(id, c);
        // this._position$.next(this.currentPosition);
        this._handleVerseTransition(this.currentPosition.ref);
      }
    }
  }

  updateVerse(newRef: VerseReference) {
    // console.log("updateVerse", this.currentPosition?.ref.toString(), newRef.toString());
    /// if there is no current verse, then we need to get it from the API
    if (this.currentPosition === undefined) {
      // console.log("updateVerse — previously undefined");
      this._handleVerseTransition(newRef);
    }
    /// if the current verse is different from the new verse, then we need to update the current verse
    else if (!this.currentPosition.ref.equals(newRef)) {
      // console.log("updateVerse — different values from before");
      this._handleVerseTransition(newRef);
    }
  }

  saveVerseData() {
    let hasChangedGlosses: boolean = this.verse?.hasChangedGlosses() || false;
    let user_id: UserId | undefined = this.currentUser?.user_id;
    let project_id: ProjectId | undefined = this.currentProject?.id;
    let canon: Canon | undefined = this.currentCanonData?.name;

    ///  handle the update logic
    if (this.verse && hasChangedGlosses && user_id && project_id && canon) {
      /// if an update is required...
      console.log("Updating verse", this.verse?.reference.toString());
      this.dbService.updateVerse(canon, this.verse, user_id, project_id)
        .catch(err => {
          console.error(`Error updating verse: ${err}`);
          console.log(canon, this.verse, user_id, project_id);
        });
    }
  }

  reloadUserData(): Promise<UserProfile> {
    if (this.currentUser?.user_id) {
      return this.setCurrentUser(this.currentUser.user_id);
    } else {
      return Promise.reject("No user_id to reload user data");
    }
  }

  setCurrentUser(user_id: UserId): Promise<UserProfile> {
    return this.dbService.getUserData(user_id)
      .then((user) => {
        this.user$.next(user);
        return user;
      });
  }


  /* Private methods */

  /// convenience
  private get verse(): Verse | undefined | null {
    return this._verse$.value;
  }

  _writeCurrentPositionToLocalStorage() {
    // console.log("Writing current position to local storage", this._position$.value?.toObject());
    if (this._position$.value) {
      localStorage.setItem("currentPosition", JSON.stringify(this._position$.value.toObject()));
    }
  }

  _readCurrentPositionFromLocalStorage() {
    const currentPosition: string | null = localStorage.getItem("currentPosition");
    if (currentPosition) {
      let position = CurrentPosition.fromJson(currentPosition);
      if (position) {
        this._position$.next(position);
        this._handleVerseTransition(position?.ref);
      }
    } else if (this._user$.value?.projects) {
      this.setToFallbackPosition();
      if (this._position$.value) {
        this._handleVerseTransition(this._position$.value.ref);
      }
    }
  }

  setToFallbackPosition() {
    if (this._user$.value?.projects) {
      const projects = Array.from(this._user$.value?.projects);
      const fallback = CurrentPosition.createFromProjectList(projects);
      if (fallback) {
        this._position$.next(fallback);
      }
    } else {
      console.error("User has no projects, so cannot set to fallback position");
    }
  }

  deepCopyVerseToTriggerRefresh() {
    if (this.verse) {
      let copy = this.verse.copyOf();
      this._verse$.next(copy);
    }
  }

  /// This should only be called when it is already known that ref
  /// is different from the current verse
  private _handleVerseTransition(ref: VerseReference) {
    let hasChangedGlosses: boolean = this.verse?.hasChangedGlosses() || false;
    let user_id: UserId | undefined = this.currentUser?.user_id;
    let project_id: ProjectId | undefined = this.currentProject?.id;
    let canon: Canon | undefined = this.currentCanonData?.name;

    ///  handle the update logic
    if (this.verse && hasChangedGlosses && user_id && project_id && canon) {
      /// if an update is required...
      console.log("Updating verse", this.verse?.reference.toString());

      /// 2024-08-30: These were formerly chained, but I don't see
      /// the need for them to be run sequentially. Should help lag
      /// times as well.
      this.dbService.updateVerse(canon, this.verse, user_id, project_id)
        .catch(err => {
          console.error(`Error updating verse: ${err}`);
          console.log(canon, this.verse, user_id, project_id);
        });
    }
    /// load the new verse
    if (project_id && user_id) {
      this._getVerseFromApi(ref, user_id, project_id);
    } else {
      console.error("Ignoring request in _handleVerseTransition: projectId or user_id is undefined", project_id, user_id);
      // console.trace();
    }
  }

  private _getVerseFromApi(ref: VerseReference, userId: UserId, projectId: ProjectId) {
    // let projectId = this.currentProject?.id;
    // let userId = this._user$.value?.user_id;
    // console.log("_getVerseFromApi", projectId, userId);
    // console.trace();
    this._verse$.next(undefined);

    this.dbService.loadVerseFromAPI(ref, userId, projectId).then(verse => {
      this._verse$.next(verse);
      if (verse) {
        this._position$.value?.setCurrentReference(projectId, verse.reference);
        this._position$.next(this._position$.value);
        this._writeCurrentPositionToLocalStorage();
      }
    })
      .catch(err => {
        console.error(`Error loading verse from API: ${err}`);
        console.trace();
      });
  }

}