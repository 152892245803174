import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, map, tap } from 'rxjs';
import { CognitoUserInfoResponse, TimedOauthCredentials } from '@models/TimedOauthCredentials';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  public accessToken$: BehaviorSubject<TimedOauthCredentials | undefined> = new BehaviorSubject<TimedOauthCredentials | undefined>(undefined);
  private _userInfo: CognitoUserInfoResponse | undefined;
  private _intervalId: NodeJS.Timeout | undefined;

  static TIMED_ACCESS_TOKEN = "TIMED_ACCESS_TOKEN";

  constructor(private http: HttpClient) { }

  /// this is only called from the synchronizer service
  public async initializeApplication(): Promise<CognitoUserInfoResponse> {
    // console.log("CognitoService.initializeApplication");
    if (!this.hasStoredCredentials) {
      return Promise.reject("No stored credentials");
    }

    try {
      const success = await this.ensureStoredAccessTokenIsFresh();
      if (success) {
        // console.log("Access token is fresh.");
        this.storeAccessToken(this.accessToken$.value as TimedOauthCredentials);

        /// set a timer to refresh the token periodically
        this.setTokenRefreshTimer();

        return await this.getUserInfo();
      } else {
        console.log("Could not get a fresh access token.");
        this.clearStoredCredentials();
        return Promise.reject("Could not get a fresh access token.");
      }
    } catch (error) {
      console.error("Error ensuring stored access token is fresh", error);
      return Promise.reject("Could not get a fresh access token.");
    }
  }

  public get hasStoredCredentials(): boolean {
    return localStorage.getItem(CognitoService.TIMED_ACCESS_TOKEN) !== null;
  }

  private setTokenRefreshTimer() {
    /// Interval time in milliseconds (25 minutes)
    /// (The docs say 30 minutes, but we'll be safe)
    const intervalTime = 25 * 60 * 1000;

    /// Clear any existing interval
    if (this._intervalId !== undefined) {
      clearInterval(this._intervalId);
    }

    // Set the interval to call the function every 25 minutes
    const outerThis = this;
    this._intervalId = setInterval(function () {
      console.log("Refreshing token");
      outerThis.refreshToken(outerThis.accessToken$.value?.refreshToken || "");
    }, intervalTime);

  }

  private clearStoredCredentials() {
    localStorage.removeItem(CognitoService.TIMED_ACCESS_TOKEN);
    this.accessToken$.next(undefined);
  }

  public get loginUrl(): string {
    return `${environment.cognito.auth_url}/login?response_type=code&client_id=${environment.cognito.client_id}&redirect_uri=${encodeURIComponent(environment.cognito.redirect_uri)}`;
  }

  private get tokenUrl(): string {
    return `${environment.cognito.auth_url}/oauth2/token`;
  }

  private get userInfoUrl(): string {
    return `${environment.cognito.auth_url}/oauth2/userInfo`;
  }

  public ensureStoredAccessTokenIsFresh(): Promise<boolean> {
    const str = localStorage.getItem(CognitoService.TIMED_ACCESS_TOKEN);
    if (str === null) {
      /// if nothing is stored, it's obviously not fresh
      return Promise.resolve(false);
    }

    /// store the access token
    this.accessToken$.next(TimedOauthCredentials.fromString(str));


    if (this.accessToken$.value?.isExpired) {
      /// TODO I'm not sure why this is happening
      if (this.accessToken$.value?.refreshToken === undefined) {
        console.error("No refresh token found");
        console.error("Follow this up. Why is it missing?");
        console.error(str);
        // console.log(this.accessToken$.value, this.accessToken$.value?.  );
        // this.clearStoredCredentials();
        return Promise.resolve(false);
      }


      /// if the token is expired, we can try to refresh it
      return this.refreshToken(this.accessToken$.value?.refreshToken || "")
        .then((success: boolean) => {
          return success;
        }).catch((error: any) => {
          console.error("Error refreshing token", error);
          return false;
        });
    } else {
      return Promise.resolve(true);
    }
  }

  /// https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
  public getToken(code: string): Promise<any> {
    // console.log(this.tokenUrl);

    let authString = "Basic " + btoa(`${environment.cognito.client_id}:${environment.cognito.client_secret}`);

    const params = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('client_id', environment.cognito.client_id)
      .set('client_secret', environment.cognito.client_secret)
      .set('redirect_uri', environment.cognito.redirect_uri)
      .set('code', code);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': authString,
    });

    const observable = this.http.post(this.tokenUrl, params.toString(), { headers })
      .pipe(
        tap((data: any) => {
          if (data && data.access_token) {
            // console.log(data);
            console.log("Access token:", data.access_token);
            this.storeAccessToken(new TimedOauthCredentials(data));
          } else {
            console.error("No access token returned", data);
          }
        })
      );

    return firstValueFrom(observable);

  }

  /// https://docs.aws.amazon.com/cognito/latest/developerguide/userinfo-endpoint.html
  private getUserInfo(): Promise<CognitoUserInfoResponse> {
    // console.log("getUserInfo");
    if (this.accessToken$.value === undefined) {
      console.error("No access token found");
      return Promise.reject("No access token found");
    }

    const headers = new HttpHeaders({
      'Authorization': "Bearer " + this.accessToken$.value.accessToken,
    });

    let observable = this.http.get(this.userInfoUrl, { headers })
      .pipe(
        tap((data: any) => {
          this._userInfo = data;
          // console.log("User info returned from Cognito", data);
        })
      );
    return firstValueFrom(observable);
  }

  private refreshToken(refreshToken: string): Promise<boolean> {
    try {
      const authString = `Basic ${btoa(`${environment.cognito.client_id}:${environment.cognito.client_secret}`)}`;
      const params = new HttpParams()
        .set('grant_type', 'refresh_token')
        .set('client_id', environment.cognito.client_id)
        .set('refresh_token', refreshToken);

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': authString,
      });

      const observable = this.http.post(this.tokenUrl, params.toString(), { headers })
        .pipe(map((data: any): boolean => {
          if (data && data.access_token) {
            console.log("Access token refreshed", data);
            this.storeAccessToken(new TimedOauthCredentials(data));
            return true;
          } else {
            console.error("Access token not refreshed", data);
            return false;
          }
        }));

      return firstValueFrom(observable);
    } catch (error) {
      console.error("Error refreshing token", error);
      return Promise.resolve(false);
    }

  }

  public storeAccessToken(token: TimedOauthCredentials) {
    this.accessToken$.next(token);
    // console.log("Storing access token", token);
    localStorage.setItem(CognitoService.TIMED_ACCESS_TOKEN, token.toString());
  }

}
