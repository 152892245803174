<div mat-subheader>My Projects</div>

<mat-card *ngFor="let project of projects" class="project-card">
  <mat-card-header>
    <mat-card-title>{{ project.title }}</mat-card-title>
    <mat-card-subtitle>{{ project.description }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <!-- Additional project details can go here -->
  </mat-card-content>
  <mat-card-actions>
    <a mat-button [routerLink]="['/workspace', project.id]" (click)="changeProject(project.id)">Workspace  <mat-icon>build</mat-icon></a>
    <a mat-button [routerLink]="['/pdfs', project.id]">PDFs  <mat-icon>picture_as_pdf</mat-icon></a>
    <a mat-button [routerLink]="['/settings/project', project.id]">Settings  <mat-icon>settings</mat-icon></a>
    <a mat-button [routerLink]="['/publication/pdf', project.id]">Publication  <mat-icon>publish</mat-icon></a>
    @if( project.userRole( user?.user_id || '') === 'admin' ) {
      <mat-menu #advancedMenu="matMenu">
        <a mat-menu-item [href]="'https://github.com/openreadersbibles/pub-'+project.id" target="_blank">GitHub  <mat-icon>code</mat-icon></a>
        <a mat-menu-item (click)="downloadProjectConfiguration(project)">Project JSON <mat-icon>download</mat-icon></a>
      </mat-menu>
    <button mat-button [matMenuTriggerFor]="advancedMenu">
      Advanced <mat-icon>more_vert</mat-icon>
    </button>
    }
  </mat-card-actions>
</mat-card>

@if( projects.length === 0 ) {
    <p>No projects found.</p>
}