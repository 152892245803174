<h2 mat-dialog-title>New Phrase Gloss</h2>
<mat-dialog-content>

  <mat-label>Click to select the words of the phrase:</mat-label>
  <app-verse-text-selector [verse]="verse" (rangeChanged)="setRange($event)" [project]="project"></app-verse-text-selector>

  <form (ngSubmit)="accept()" #myForm="ngForm">
    <mat-form-field>
    <mat-label>Enter plain text or markdown</mat-label>
    <!-- <input matInput [(ngModel)]="markdown" [ngModelOptions]="{standalone: true}" [dir]="synchronizerService.currentProject?.layout_direction || 'ltr'"/> -->
    <input matInput [(ngModel)]="markdown" [ngModelOptions]="{standalone: true}" [appProjectTextStyle]="project"/>
  </mat-form-field>
  <p>Preview:</p>
  <p [innerHTML]="markdown() | markdown"></p>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onDismiss()">Dismiss</button>
  <button mat-flat-button (click)="accept()">Add Gloss</button>
</mat-dialog-actions>