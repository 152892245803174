<main>
<ng-container *ngIf="cognitoService.hasStoredCredentials">
  <h2 *ngIf="user">Welcome, {{user.user_id}}!</h2>
  <!-- <app-workspace *ngIf="synchronizerService.user$.value !== undefined"></app-workspace> -->
  <!-- <app-feedback-message *ngIf="dbService.user$.value === undefined" message="We couldn't get your information from our server. Try logging in again. If the problem persists, please get in touch with the developers." level="error"></app-feedback-message> -->
  <p>Thanks for signing up. Click on the menu to see your options.</p>
  <ul>
    <li>Have a look at <a mat-list-item target="_blank" href="https://docs.openreadersbibles.org/">the documentation</a> to get an overview of process.</li>
    <li>Look at some samples to see what's possible.</li>
  </ul>

</ng-container>
<ng-container *ngIf="!cognitoService.hasStoredCredentials">
  <p>This is a website that allows you to make reader's Bibles in your own language. A reader's Bible is an edition of the Greek or Hebrew Bible that helps readers with parsings, and with the definitions of rarer words. Usually that would be a lot of work, but this website takes care of the difficult parts for you.</p>
  <ul>
    <li>Have a look at <a mat-list-item target="_blank" href="https://docs.openreadersbibles.org/">the documentation</a> to get an overview of process.</li>
    <li>Look at some samples to see what's possible.</li>
  </ul>
  <p>You can <button (click)="signUpConfirmation()" class="button-link">click here</button> to get started by creating an account. If you have an account already, you can sign in with the button on the top right.</p>


</ng-container>
</main>